import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}
  private getAuthHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storageService.getCookie('userSession')}`,
    });
  }
  private API_URL = 'https://api.thumshop.de';

  getAllCategories() {
    return this.http.get(this.API_URL + '/api/v1/categories', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getCategoryByID(cat: string) {
    let url: string = this.API_URL + '/api/v1/categories/category/' + cat;
    return this.http.get(url, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  searchCoins(seachvalue: String) {
    return this.http.get(
      this.API_URL + '/api/v1/products/search/' + seachvalue,
      {
        headers: this.getAuthHeaders(),
        withCredentials: true,
      }
    );
  }
  getCategoriesById(cat: string) {
    let url: string = this.API_URL + '/api/v1/products/category/' + cat;
    return this.http.get(url, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  getCoinById(coin: string) {
    let url: string = this.API_URL + '/api/v3/products/' + coin;
    return this.http.get(url, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getRelatedCoins(coin: string) {
    let url: string = this.API_URL + '/api/v3/products/related/' + coin;
    return this.http.get(url, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  getShippingOptions() {
    let url: string = this.API_URL + '/api/v1/products/shipping ';
    return this.http.get(url, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  getBestseller() {
    return this.http.get(this.API_URL + '/api/v1/bestseller', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  getAllCoins() {
    return this.http.get(this.API_URL + '/api/v3/products/shopcoins', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getTimerProduct() {
    return this.http.get(this.API_URL + '/api/v3/products/timerproduct', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getAllCountries() {
    return this.http.get(this.API_URL + '/api/v1/categories/countries', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getAllConds() {
    return this.http.get(this.API_URL + '/api/v1/categories/conditions', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  getConvoluts() {
    return this.http.get(this.API_URL + '/api/v1/products/convoluts', {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }

  saveCoin(obj: any) {
    return this.http.post<any>(this.API_URL + '/api/v1/products', obj, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  updateCoin(obj: any, id: number) {
    return this.http.patch<any>(this.API_URL + '/api/v1/products/' + id, obj, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
  changeShop(id: number) {
    return this.http.get<any>(this.API_URL + '/api/v1/products/shop/' + id, {
      headers: this.getAuthHeaders(),
      withCredentials: true,
    });
  }
}
